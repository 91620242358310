<script lang="ts">export let columns = 3;
export let space = 'normal';
</script>

<li class="columns-{columns} space-{space}">
  <slot></slot>
</li>

<style type="text/scss">li {
  display: flex;
}

.space-normal {
  padding: 0.75rem;
}

.space-wide {
  padding: 1.25rem;
}

.columns-1 {
  width: 100%;
}

.columns-2 {
  width: 50%;
}

.columns-3 {
  width: 33.33%;
}

.columns-32 {
  width: 33.33%;
}

.columns-432 {
  width: 25%;
}

.columns-4 {
  width: 25%;
}

@media only screen and (max-width: 1440px) {
  .columns-432 {
    width: 33.33%;
  }

  .columns-32 {
    width: 50%;
  }
}
@media only screen and (max-width: 1069px) {
  .columns-432 {
    width: 50%;
  }

  .columns-3 {
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  li {
    width: 100% !important;
  }
}</style>
