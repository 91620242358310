<script lang="ts">export let kind;
export let space = 'normal';
</script>

<ul class="{kind} space-{space}">
  <slot></slot>
</ul>

<style type="text/scss">ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
}

.space-normal {
  margin: 0 -0.75rem;
}

.space-wide {
  margin: 0 -1.25rem;
}

.left {
  justify-content: flex-start;
}</style>
